#Home{
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  background-image: url('../images/bg.png');
  background-size: 500px;
  background-position: center;
  overflow: hidden;
  transition: margin-left 0.5s;
}

#Home.active{
  margin-left: -180px;
}

#Home .container{
  width: 100%;
  max-width: 360px;
  padding: 0 10px;
  display: grid;
  place-items: center;
}

#Home .container .img{
  width: 300px;
}

#Home .container .img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

#Home .text-btns{
  width: 100%;
  text-align: center;
}

#Home .text-btns p{
  color: #363636;
  font-size: 0.9rem;
  margin: 10px 0;
}

#Home .text-btns .btns a{
  display: inline-block;
  width: 100%;
  height: 42px;
  line-height: 42px;
  border: 1px solid #7e7e7e;
  border-radius: 20px;
  margin: 7px 0;
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 1px;
  transition: 0.2s ease;
  color: #000;
}


#Home .text-btns .btns .login-btn{
  background: #e8505b;
  color: #fff;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

#Home .text-btns .btns a:hover{
  background: #222;
  color: #fff;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

#Home .text-btns span{
  display: inline-block;
  font-weight: 600;
  color: #222;
  font-size: 0.9rem;
  margin-top: 10px;
  margin-bottom: 5px;
}

#Home .text-btns .via-social{
  display: flex;
  justify-content: center;
  align-items: center;
}

#Home .text-btns .via-social a{
  width: 33px;
  height: 33px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.8rem;
  margin: 5px;
}

#Home .text-btns .via-social a:nth-child(1){
  background: #155ee6;
}

#Home .text-btns .via-social a:nth-child(2){
  background: #eb1515;
}

#Home .text-btns .via-social a:nth-child(3){
  background: #197cfd;
}